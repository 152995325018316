window.addEventListener('DOMContentLoaded', function () {

    const duplicatePageButtons = document.getElementsByClassName('m4c-duplicate-post');

    if (duplicatePageButtons.length > 0) {

        document.addEventListener('click', event => {
            if (event.target.matches('.m4c-duplicate-post')) {

                // Prevent additional clicks on the buttons while the page submits the cloning request and reloads
                for (const button of duplicatePageButtons) {
                    button.style.pointerEvents = 'none';
                }
            }
        });
    }
});